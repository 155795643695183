import { Stack, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'

import Button from 'src/common/components/button/Button'
import { COLOR_THEME } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import ArrowRight from 'src/components/icons/ArrowRight'
import GridView from 'src/components/multicolumn-content/GridView'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import CarouselView from 'src/components/multicolumn-content/CarouselView'
import { CONTENT_ALIGNMENT } from 'src/components/multicolumn-content/constants'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { TextButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { background_color } = props

  let backgroundColor

  if (background_color === COLOR_THEME.CRISSY_FIELD) {
    backgroundColor = SECONDARY_COLOR.LIGHT['40']
  } else if (background_color === COLOR_THEME.THE_BAKER_BEACH) {
    backgroundColor = theme.palette.presidio.color.BAKER_BEACH_WHITE
  } else {
    backgroundColor = theme.palette.presidio.color.LIGHT_BACKGROUND
  }

  return {
    container: {
      backgroundColor: backgroundColor,
      padding: `${rem(40)} ${rem(24)}`,
      [theme.breakpoints.up('md')]: {
        padding: `${rem(64)} ${rem(40)}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${rem(156)}`,
      },
      gap: rem(24),
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        textAlign: 'unset',
      },
    },
    frame1: {
      display: 'flex',
      gap: rem(22),
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
      },
    },
    headingCol: {
      flex: '0 0 calc(100% / 12 * 9)',
    },
    ctaCol: {
      flex: 1,
    },
    heading: {
      ...theme.typography.h2,
      color: theme.palette.primary.dark,
    },
    subheading: {
      ...theme.typography.body.default,
      color: theme.palette.presidio.color.MILD_GRAY,
    },
  }
})

export default function MulticolumnContent(props) {
  const { data } = props
  if (!data) return null

  const { multi_column_content, section_id } = data
  if (!multi_column_content) return null

  const {
    heading,
    sub_heading,
    cta,
    background_color,
    content_items,
    columns,
    display_carousel,
    content_items_in_single_column,
    content_alignment,
    framing_options,
  } = multi_column_content
  if (
    !content_items ||
    !Array.isArray(content_items) ||
    content_items.length === 0
  ) {
    return null
  }

  const { classes } = useStyles({ background_color })
  const router = useRouter()

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  function cardActionClickHandler(action) {
    if (action.target === '_blank') {
      window.open(action.url, '_blank')
    } else {
      router.push(action.url)
    }
  }

  const imageHeight = React.useMemo(() => {
    if (xlUp) {
      if (content_items.length >= 5) {
        if (columns === '4') return 198
        return 154
      }
      if (content_items.length === 4) return 198
      if (content_items.length === 3) return 270
      return 414
    }

    if (lgUp) {
      if (content_items.length >= 5) {
        if (columns === '4') return 163
        return 126
      }
      if (content_items.length === 4) return 163
      if (content_items.length === 3) return 223
      return 345
    }
    if (mdUp) {
      if (content_items.length >= 5) {
        if (columns === '4') return 106
        return 148
      }
      if (content_items.length === 4) return 106
      if (content_items.length === 3) return 148
      return 231
    }

    if (content_items_in_single_column) return 234
    return 116
  }, [xlUp, lgUp, mdUp, columns])

  const carouselColumns = React.useMemo(() => {
    if (lgUp) {
      if (content_items.length >= 5) return columns
      return '4'
    }
    if (mdUp) return '4'
    return '2'
  }, [xlUp, lgUp, mdUp])

  const frameColor = React.useMemo(() => {
    if (background_color === COLOR_THEME.BACKGROUND) {
      return theme.palette.presidio.color.BAKER_BEACH_WHITE
    }
    return theme.palette.presidio.color.LIGHT_BACKGROUND
  }, [background_color])

  const frameWidth = React.useMemo(() => {
    if (framing_options === 'framed') {
      if (lgUp) return 24
      if (mdUp) return 16

      if (content_items_in_single_column) return 24
      return 16
    }

    return 0
  }, [background_color, mdUp, lgUp, xlUp])

  const layout = React.useMemo(() => {
    if (display_carousel) {
      if (
        (lgUp && content_items.length > 4 && columns === '4') ||
        (lgUp && content_items.length > 5) ||
        (!lgUp && mdUp && content_items.length > 4) ||
        (!mdUp && content_items.length > 2 && !content_items_in_single_column)
      ) {
        return (
          <CarouselView
            data={content_items}
            imageHeight={imageHeight}
            columns={carouselColumns}
            centerAlign={content_alignment === CONTENT_ALIGNMENT.CENTER}
            frameColor={frameColor}
            frameWidth={frameWidth}
          />
        )
      }
    }

    return (
      <GridView
        data={content_items}
        imageHeight={imageHeight}
        columns={columns}
        centerAlign={content_alignment === CONTENT_ALIGNMENT.CENTER}
        singleColumnForMobile={content_items_in_single_column}
        frameColor={frameColor}
        frameWidth={frameWidth}
      />
    )
  }, [content_items, imageHeight, xlUp, mdUp, lgUp])

  return (
    <div
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
    >
      <div className={classes.frame1}>
        <Stack
          className={classes.headingCol}
          spacing={2}
          data-ga-location="multicolumn_headline"
        >
          <Typography className={classes.heading}>{heading}</Typography>
          <Typography className={classes.subheading}>{sub_heading}</Typography>
        </Stack>
        {cta && (
          <a
            href={cta?.url}
            onClick={(e) => {
              e.preventDefault()
              cardActionClickHandler(cta)
            }}
            className={classes.ctaCol}
            data-ga-location="multicolumn_headline_cta"
          >
            <Button
              className={classes.button}
              variant="text"
              endIcon={
                isUrlExternal(cta?.url) ? (
                  <TextButtonExternalLink />
                ) : (
                  <ArrowRight />
                )
              }
            >
              {cta.title}
            </Button>
          </a>
        )}
      </div>
      {layout}
    </div>
  )
}

MulticolumnContent.propTypes = {
  data: PropTypes.shape({
    multi_column_content: PropTypes.shape({
      heading: PropTypes.string,
      sub_heading: PropTypes.string,
      cta: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.oneOf(['', '0', '_blank']),
      }),
      background_color: PropTypes.oneOf([
        COLOR_THEME.CRISSY_FIELD,
        COLOR_THEME.THE_BAKER_BEACH,
        COLOR_THEME.BACKGROUND,
      ]),
      content_items: PropTypes.array,
      columns: PropTypes.string,
      display_carousel: PropTypes.bool,
      content_items_in_single_column: PropTypes.bool,
      content_alignment: PropTypes.oneOf([
        CONTENT_ALIGNMENT.LEFT,
        CONTENT_ALIGNMENT.CENTER,
      ]),
      framing_options: PropTypes.oneOf(['framed', 'full_bleed']),
    }),
    section_id: PropTypes.string,
  }),
}
