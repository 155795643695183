import { useState } from 'react'
import Image from 'next/image'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'

import Button from 'src/common/components/button/Button'
import { rem } from 'src/common/utils/css'
import sanitize from 'src/common/utils/js/sanitize'
import theme from 'src/styles/theme'
import ArrowRight from 'src/components/icons/ArrowRight'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { TextButtonExternalLink } from 'src/common/components/externalSiteIcon'
import { CARD_STATE } from 'src/common/components/card/constants'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { imageHeight, centerAlign, frameColor, frameWidth } = props

  return {
    container: {
      cursor: 'pointer',
    },
    imageContainer: {
      width: '100%',
      height: imageHeight ? rem(imageHeight) : rem(126),
      position: 'relative',
      overflow: 'hidden',
      flexShrink: 0,
      border: `${rem(frameWidth)} solid ${frameColor}`,
    },
    title: {
      ...theme.typography.h3,
      color: theme.palette.primary.dark,
      textAlign: centerAlign ? 'center' : 'left',
      [theme.breakpoints.down('lg')]: {
        fontSize: rem(24),
      },
    },
    caption: {
      ...theme.typography.body.default,
      color: theme.palette.presidio.color.DARK_GRAY,
      textAlign: centerAlign ? 'center' : 'left',
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
      fontSize: rem(16),
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: centerAlign ? 'center' : 'stretch',
      justifyContent: 'space-between',
      gap: rem(16),
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(16),
    },
    button: {
      '& div': {
        textAlign: centerAlign ? 'center' : 'unset',
      },
    },
  }
})

export default function Item(props) {
  const { data, imageHeight, centerAlign, frameColor, frameWidth, gaTag } =
    props
  if (!data) return null

  const { image, title, multi_column_caption, cta } = data

  const { classes } = useStyles({
    imageHeight,
    centerAlign,
    frameColor,
    frameWidth,
  })

  const [cardState, setCardState] = useState(CARD_STATE.DEFAULT)

  const handleMouseEnter = (e) => {
    setCardState(CARD_STATE.HOVER)
  }

  const handleMouseLeave = (e) => {
    setCardState(CARD_STATE.DEFAULT)
  }

  const handleMouseDown = (e) => {
    setCardState(CARD_STATE.ACTIVE)
  }

  const handleMouseUp = (e) => {
    if ('ontouchstart' in document.documentElement) {
      setCardState(CARD_STATE.DEFAULT)
    } else {
      setCardState(CARD_STATE.HOVER)
    }
  }

  const linkClickHandler = useLinkClickHandler()

  return (
    <Stack
      className={classes.container}
      onMouseEnter={cta ? handleMouseEnter : null}
      onMouseLeave={cta ? handleMouseLeave : null}
      onMouseDown={cta ? handleMouseDown : null}
      onMouseUp={cta ? handleMouseUp : null}
      onClick={(e) => {
        e.preventDefault()
        if (cta && cta?.url) {
          linkClickHandler(cta)
        }
      }}
      data-ga-location={gaTag}
      spacing={2}
      width="100%"
      alignItems={centerAlign ? 'center' : 'left'}
    >
      <div className={classes.imageContainer}>
        <Image
          src={image?.url}
          alt={image?.alt || 'Card image'}
          title={image?.title}
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.contentContainer}>
          {title && (
            <Typography component="h3" className={classes.title}>
              {title}
            </Typography>
          )}

          {multi_column_caption && (
            <Typography component="div" className={classes.caption}>
              {sanitize(multi_column_caption, gaTag)}
            </Typography>
          )}
        </div>

        {cta && (
          <Button
            variant="text"
            hover={cta ? cardState === CARD_STATE.HOVER : false}
            active={cta ? cardState === CARD_STATE.ACTIVE : false}
            endIcon={
              isUrlExternal(cta?.url) ? (
                <TextButtonExternalLink />
              ) : (
                <ArrowRight />
              )
            }
          >
            {cta?.title}
          </Button>
        )}
      </div>
    </Stack>
  )
}

Item.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
    }),
    title: PropTypes.string,
    multi_column_caption: PropTypes.string,
    cta: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.oneOf(['', '0', '_blank']),
    }),
  }),
  imageHeight: PropTypes.number,
  centerAlign: PropTypes.bool,
  frameColor: PropTypes.oneOf([
    theme.palette.presidio.color.BAKER_BEACH_WHITE,
    theme.palette.presidio.color.LIGHT_BACKGROUND,
  ]),
  frameWidth: PropTypes.number,
  gaTag: PropTypes.string,
}
