import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

import Item from 'src/components/multicolumn-content/Item'

export default function GridView(props) {
  const {
    data,
    columns,
    imageHeight,
    centerAlign,
    singleColumnForMobile,
    frameColor,
    frameWidth,
  } = props

  const list = React.useMemo(() => {
    let xl
    let lg
    let md
    let sm
    let xs

    if (data.length <= 2) {
      xl = 6
      lg = 6
      md = 6
    } else if (data.length === 3) {
      xl = 4
      lg = 4
      md = 4
    } else if (columns === '4' || data.length === 4) {
      xl = 3
      lg = 3
      md = 3
    } else {
      xl = 2.4
      lg = 2.4
      md = 4
    }

    if (singleColumnForMobile) {
      sm = 12
      xs = 12
    } else {
      sm = 6
      xs = 6
    }

    return data.map((item, i) => (
      <Grid
        key={i}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        item
        display="flex"
        alignItems="stretch"
      >
        <Item
          data={item}
          imageHeight={imageHeight}
          centerAlign={centerAlign}
          frameColor={frameColor}
          frameWidth={frameWidth}
          gaTag={`multicolumn_${i + 1}`}
        />
      </Grid>
    ))
  }, [imageHeight])

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent={centerAlign ? 'center' : 'left'}
      alignItems="stretch"
    >
      {list}
    </Grid>
  )
}

GridView.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.string,
  imageHeight: PropTypes.number,
  centerAlign: PropTypes.bool,
  singleColumnForMobile: PropTypes.bool,
  frameColor: PropTypes.string,
  frameWidth: PropTypes.number,
}
