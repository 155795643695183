export const CARD_STATE = {
  HOVER: 'hover',
  ACTIVE: 'active',
  DEFAULT: 'default',
}

export const CARD_VARIANT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  VERTICAL_WIDE: 'vertical_wide',
  VERTICAL_PACKED: 'vertical_packed',
  FEATURE: 'feature',
  MOBILE: 'mobile',
  EDITORIAL_1440: 'editorial_1440',
  EDITORIAL_375: 'editorial_375',
  ABBREVATED_1440: 'abbrevated_1440',
  ABBREVATED_375: 'abbrevated_375',
}

export const CARD_TYPE = {
  ITINERARIES: 'itineraries',
  POST: 'post',
  PLACES: 'places',
  PRESS: 'press',
  PAGE: 'page',
  TRAIL: 'trail',
  EVENT: 'tribe_events',
  CATEGORICAL_EXCLUSIONS: 'cat-exclusions',
}
