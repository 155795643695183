import { Box, Stack } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'src/common/components/carousel/Carousel'
import FloatingActionButton from 'src/common/components/floating-action-button/FloatingActionButton'
import { CAROUSEL_TYPE } from 'src/common/components/carousel/constants'
import ArrowLeft from 'src/components/icons/ArrowLeft'
import ArrowRight from 'src/components/icons/ArrowRight'
import Item from 'src/components/multicolumn-content/Item'

export default function CarouselView(props) {
  const { data, columns, imageHeight, centerAlign, frameColor, frameWidth } =
    props

  const [activeIndex, setActiveIndex] = React.useState(0)
  const [disableLeft, setDisableLeft] = React.useState(false)
  const [disableRight, setDisableRight] = React.useState(false)
  let touchstartX
  let touchendX
  let touchstartY
  let touchendY

  function handleGesture() {
    if (
      touchendX < touchstartX &&
      touchstartX - touchendX > 80 &&
      Math.abs(touchstartY - touchendY) < 20
    ) {
      if (!disableRight) setActiveIndex((index) => index + 1)
    }
    if (
      touchendX > touchstartX &&
      touchendX - touchstartX > 80 &&
      Math.abs(touchendY - touchstartY) < 20
    ) {
      if (!disableLeft) setActiveIndex((index) => index - 1)
    }
  }
  const list = React.useMemo(
    () =>
      data.map((item, i) => (
        <Box
          onTouchStart={(e) => {
            touchstartX = e.changedTouches[0].screenX
            touchstartY = e.changedTouches[0].screenY
          }}
          onTouchEnd={(e) => {
            touchendX = e.changedTouches[0].screenX
            touchendY = e.changedTouches[0].screenY
            handleGesture()
          }}
          key={i}
          display="flex"
          alignItems="stretch"
        >
          <Item
            data={item}
            imageHeight={imageHeight}
            centerAlign={centerAlign}
            frameColor={frameColor}
            frameWidth={frameWidth}
            gaTag={`multicolumn_${i + 1}`}
          />
        </Box>
      )),
    [imageHeight, activeIndex, disableLeft, disableRight]
  )

  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        spacing={20}
        variant={CAROUSEL_TYPE.SECONDARY}
        columns={parseInt(columns, 10)}
        movableLeft={(v) => setDisableLeft(!v)}
        movableRight={(v) => setDisableRight(!v)}
      >
        {list}
      </Carousel>

      {(!disableLeft || !disableRight) && (
        <Stack alignItems="flex-end">
          <Stack direction="row" spacing={2}>
            <FloatingActionButton
              onClick={(e) => setActiveIndex((index) => index - 1)}
              data-ga-location="multicolumn_left"
              disabled={disableLeft}
              title="Previous"
              aria-label="Previous"
            >
              <ArrowLeft />
            </FloatingActionButton>
            <FloatingActionButton
              onClick={(e) => setActiveIndex((index) => index + 1)}
              data-ga-location="multicolumn_right"
              disabled={disableRight}
              title="Next"
              aria-label="Next"
            >
              <ArrowRight />
            </FloatingActionButton>
          </Stack>
        </Stack>
      )}
    </>
  )
}

CarouselView.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.string,
  imageHeight: PropTypes.number,
  centerAlign: PropTypes.bool,
  frameColor: PropTypes.string,
  frameWidth: PropTypes.number,
}
